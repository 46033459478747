<template>
    <v-container fluid>
        <v-card outlined>
            <v-card-subtitle>
                Listado de <strong>membresías</strong>, puedes agrupar por colectividad.
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                        <select-colectivos 
                        :colectivos="colectivos" 
                        @colectivo-change="changeColectividad">
                        </select-colectivos>
                    </v-col>
                    <v-col>
                        <v-btn color="azul_med" @click.native="buscarColectivo" dark>Buscar</v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="verOptDownloadCole">
                    <v-btn color="azul_med" @click="DescargaZip" dark small class="mt-2">
                        Descargar tarjetas
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col class="d-flex flex-wrap align-center justify-end">
                <v-chip class="ma-2" color="azul_med" label dark>
                    <v-icon start>mdi-chevron-right-circle-outline</v-icon>
                    <v-list-item-subtitle>Activas: {{ this.activas }}</v-list-item-subtitle>
                </v-chip>
                <v-chip class="ma-2" color="rojoalert" label dark>
                    <v-icon start>mdi-alert-circle-outline</v-icon>
                    <v-list-item-subtitle>Impago: {{ this.suspendidas }}</v-list-item-subtitle>
                </v-chip>
                <v-chip class="ma-2" color="grey" label dark>
                    <v-icon start>mdi-alert-circle-outline</v-icon>
                    <v-list-item-subtitle>Bajas: {{ this.totalbajas }}</v-list-item-subtitle>
                </v-chip>
            </v-col>

        </v-row>
        <tabla-membresias 
        :membresias="this.contratos" 
        :actions="tableActions" 
        @action-selected="onActionSelected">
        </tabla-membresias>
        <pases-medicos-dialog
            ref="modalPaseMedico"
            :isOpen.sync="dialogPases" 
            :item="this.MembresiaSelect"
            @crear-cita="crearCrearCita"
        >
        </pases-medicos-dialog>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import EndPointNet from "@/functions/axios/axios-bearer";


export default {
    name: 'AgenteMembresias',
    computed: {
        ...mapGetters(['getCODAGE'])
    },
    components: {
        SelectColectivos: () => import('@/components/selectColectivo.vue'),
        TablaMembresias: () => import('@/components/TablaMembresias.vue'),
        PasesMedicosDialog: () => import('@/components/PasesMedicosDialog.vue'),

    },
    async mounted() {
        await this.getColectivos()
        await this.getMembresias()
    },
    data() {
        return {
            verOptDownloadCole: false,
            MembresiaSelect: {},
            dialogPases: false,


            activas: 0,
            suspendidas: 0,
            totalbajas: 0,

            colectivos: [],
            search: '',

            idcolectividad: null,
            contratos: [],


            headers: [
                { text: "#", value: "index", align: "start", width: 10 },
                { text: 'Agente', value: 'Agente', sortable: false, width: 100 },
                { text: 'Membresia', value: 'GUID', sortable: true, width: 80 },
                { text: 'Plan', value: 'Plan', sortable: false, width: 150 },
                { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: true, width: 50 },
                { text: 'Alta', value: 'FecAlta', sortable: true, width: 50 },
                { text: 'Contratante', value: 'Contratante', sortable: false },
                { text: 'Titular', value: 'Titular', sortable: false },
                { text: 'Estatus', value: 'Estatus', sortable: true },
                { text: 'Opciones', value: 'Membresia', sortable: false },
            ],
            tableActions: [
                //'BENEFICIADOS',
                //'RECIBOS',
                'PASES MEDICOS',
                'TARJETA DIGITAL',
                //'URGENCIAS',
                //'CONTRATO'
            ]
        }
    },
    methods: {
        crearCrearCita(cita){
            if (cita.beneficiado.Id  && cita.especialidad  && cita.medico.IdMedico && cita.membresia) {
                this.$loading(true);
                EndPointNet(`membresia/citamedica`, "POST",
                {
                    idMem: cita.membresia,
                    idEsp: cita.especialidad,
                    idMed: cita.medico.IdMedico,
                    idBen: cita.beneficiado.Id
                }
                )
                .then((response) => {
                    if (response.status === 200) {

                    this.actualizaNumCitas(cita.membresia)

                    this.$loading(false);
                    this.$swal({
                        position: 'top-end',
                        title: 'Correcto',
                        text: 'Su pase médico se genero correctamente',
                        icon: 'success',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 1500,
                        backdrop: false
                    })
                    this.$refs.modalPaseMedico.reloadTable()
                    } else {
                    this.isModalCita = false
                    this.$loading(false);
                    this.$swal({
                        title: 'Infomación',
                        text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        backdrop: false
                    });
                    }
                });
            } else {
                this.especialidad = ''
                this.medico = ''
                this.beneficiado = ''
                this.$swal({
                // position: 'top-end',
                title: 'Infomación',
                text: `Selecciona una especialidad, un médico y para quien será el pase.`,
                icon: 'warning',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                // timer: 1500,
                backdrop: false,
                })
            }
            },
        actualizaNumCitas(membresiaId) {
            EndPointNet(`Membresia/Numerocitas${this.MembresiaSelect.Id}`, "GET")
                .then((response) => {
                if (response.status === 200) {
                    //actualiza localmente sin recargar la tabla membresias
                    const index = this.contratos.findIndex(item => item.Id ===  membresiaId);
                    if (index !== -1) {
                    this.contratos[index].NumCitas = response.data[0].NumCitas;
                    }

                    this.MembresiaSelect.NumCitas = response.data[0].NumCitas
                    //this.MembresiaSelect.Estatus  = response.data[0].Estatus
                }
                })
                .catch(() => {
                this.$loading(false);
                });
        },
        DescargaZip() {
            console.log(this.idcolectividad)
            if(this.idcolectividad){
                this.$loading(true);
                var urltarjet = `${process.env.VUE_APP_FORMATOS}Tarjeta/ColectividadAgente?guid=${this.idcolectividad}&agent=${this.getCODAGE}`;
                var newTab = window.open(urltarjet);
                if (newTab) {
                    newTab.focus();
                    this.$loading(false);
                }
            }
        },
        changeColectividad(value) {
            this.idcolectividad = value
            this.verOptDownloadCole = value !== null;
        },
        onActionSelected(action, item) {
            this.MembresiaSelect = { ...item }
            switch (action) {
                case 'BENEFICIADOS':
                    //this.dialogBeneficiados = true
                    break;
                case 'PASES MEDICOS':
                    this.dialogPases = true
                    break;
                default:
                    break;
            }
        },
        async getColectivos() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `colectivo/agente?idAgent=${this.getCODAGE}`,
                    'GET'
                )
                this.$loading(false)
                if (response.status === 200) {
                    this.colectivos = response.data
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                    backdrop: false,
                })
            }
        },
        buscarColectivo() {
            this.getMembresias()
        },
        async getMembresias() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `membresia/agente?idAgent=${this.getCODAGE || null}&Colectivo=${this.idcolectividad || null}`,
                    'GET'
                )
                this.$loading(false)

                if (response.status === 200) {
                    this.contratos = response.data
                    this.activas = this.contratos.filter(item => item.Estatus === 'ACTIVO').length;
                    this.suspendidas = this.contratos.filter(item => item.Estatus === 'SUSPENDIDO').length;
                    this.totalbajas = this.contratos.filter(item => item.Estatus === 'BAJA').length;
                } else {
                    this.$mostrarSwal({
                        title: 'Información',
                        text: 'No tienes pases suficientes. Puedes hacer una recarga de 5 a un costo especial, contacta a tu agente.',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        showCloseButton: true,
                        showConfirmButton: true,
                    })
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
        }
    }
}
</script>

<style scoped>
/* Puedes agregar estilos específicos para esta vista */
</style>