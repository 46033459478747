<template>
  <v-container>
    <v-combobox clearable dense hide-selected outlined v-model="selectedValue" :items="items" item-text="Especialidad"
      item-value="IdEsp" label="Selecciona una especialidad médica" persistent-hint return-object single-line
      width="100%" @change="emitValue" class="my-combobox">
    </v-combobox>
  </v-container>
</template>

<script>
import EndPointNet from "@/functions/axios/axios-bearer";

export default {
  name: "SelectEspecialidad",
  data() {
    return {
      selectedValue: '',
      items: []
    };
  },
  mounted() {
    this.getEspecialidades()
  },
  methods: {
    emitValue(value) {
      console.log(value)
      this.$emit("value-changed", value?.Id);
    },
    getEspecialidades() {
      this.$loading(true);
      EndPointNet(`especialidad`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
