<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-subtitle>
        Puedes <strong>buscar</strong> por número de membresía, nombre del titular, nombre del contratante, agente o
        colectividad.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <!-- Campo de búsqueda -->
          <v-col cols="12" m="3" md="3" lg="3">
            <v-text-field clearable class="col" v-model="NombreMem" label="Titular / membresía / contratante" outlined
              dense @change="changeSearch" @keyup.enter="Buscar">
            </v-text-field>
          </v-col>
          <!-- Selector de agentes -->
          <v-col cols="12" m="2" md="3" lg="2">
            <list-agentes @agent-change="changeAgent"></list-agentes>
          </v-col>
          <!-- Selector de colectivos y botón de descarga -->
          <v-col cols="12" m="2" md="3" lg="2">
            <select-colectivos 
              :colectivos="colectivos" 
              @colectivo-change="changeColectividad">
            </select-colectivos>
          </v-col>
          <!-- Botón de búsqueda -->
          <v-col>
            <v-btn color="azulfuerte" @click="Buscar" dark>
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="verOptDownloadCole">
          <v-btn color="azul_med" @click="DescargaZip" dark small class="mt-2">
            Descargar tarjetas
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <tabla-membresias
    :membresias="this.contratos" 
    :actions="tableActions" 
    @action-selected="onActionSelected"></tabla-membresias>

    <list-beneficiarios 
      :isOpen.sync="dialogBeneficiados" 
      :Membresia="this.MembresiaSelect?.Id || ''"
    ></list-beneficiarios>
    <pases-medicos-dialog
      ref="modalPaseMedico"
      :isOpen.sync="dialogPases" 
      :item="this.MembresiaSelect"
       @crear-cita="crearCrearCita"
      >
    </pases-medicos-dialog>

  </v-container>
</template>
<script>

import EndPointNet from "@/functions/axios/axios-bearer";


export default {
  name: 'Membresias',
  computed: {
    mem() {
      return this.$route.params.mem;
    }
  },
  components: {
    SelectColectivos: () => import('@/components/selectColectivo.vue'),
    ListAgentes: () => import('@/components/Forms/AgentesListComponent.vue'),
    TablaMembresias: () => import('@/components/TablaMembresias.vue'),
    //ListPagos: () => import('@/components/Pagos/modalListPagosComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
    //ModalPago: () => import('@/components/Pagos/PagoComponent.vue'),
    PasesMedicosDialog: () => import('@/components/PasesMedicosDialog.vue'),

  },
  mounted() {
    this.NombreMem = this.$route.params.mem || null;
    if(this.NombreMem){
      this.getMembresias()
    }
    this.getColectivos() 
  },
  data() {
    return {
      colectivos: [],

      isModLisPagos: false,
      dialogBeneficiados: false,
      dialogPagoShow: false,
      dialogPases: false,
      isModalCitas: false,


      MembresiaSelect: {},


      NombreMem: null,
      verOptDownloadCole: false,
      contratos: [],
      idagenteSelect: null,
      idcolectividadSelect: null,
      tableActions: [
        //'BENEFICIADOS',
        //'RECIBOS',
        'PASES MEDICOS',
        'TARJETA DIGITAL',
        //'URGENCIAS',
        //'CONTRATO'
      ],
    }
  },
  methods: {
    crearCrearCita(cita){
      if (cita.beneficiado.Id  && cita.especialidad  && cita.medico.IdMedico && cita.membresia) {
        this.$loading(true);
        EndPointNet(`membresia/citamedica`, "POST",
          {
            idMem: cita.membresia,
            idEsp: cita.especialidad,
            idMed: cita.medico.IdMedico,
            idBen: cita.beneficiado.Id
          }
        )
          .then((response) => {
            if (response.status === 200) {

              this.actualizaNumCitas(cita.membresia)

              this.$loading(false);
              this.$swal({
                position: 'top-end',
                title: 'Correcto',
                text: 'Su pase médico se genero correctamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500,
                backdrop: false
              })
              this.$refs.modalPaseMedico.reloadTable()
            } else {
              this.isModalCita = false
              this.$loading(false);
              this.$swal({
                title: 'Infomación',
                text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                backdrop: false
              });
            }
          });
      } else {
        this.especialidad = ''
        this.medico = ''
        this.beneficiado = ''
        this.$swal({
          // position: 'top-end',
          title: 'Infomación',
          text: `Selecciona una especialidad, un médico y para quien será el pase.`,
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          showCloseButton: false,
          // timer: 1500,
          backdrop: false,
        })
      }
    },
    async getColectivos() {
        try {
            this.$loading(true)
            const response = await EndPointNet(
                `colectivo/admin`,
                'GET'
            )
            this.$loading(false)

            if (response.status === 200) {
                this.colectivos = response.data
            }
        } catch (error) {
            this.$loading(false)
            this.$mostrarSwal({
                title: 'Error',
                text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
            })
        }
    },
    changeColectividad(value) {
      this.idcolectividadSelect = value
      this.verOptDownloadCole = value !== null;
      if (value) {
        this.idagenteSelect = null
        this.NombreMem = null
      }
    },
    changeAgent(value) {
      this.idagenteSelect = value
      if (value) {
        this.idcolectividadSelect = null
        this.NombreMem = null
      }
    },
    async getMembresias() {
            try {
                this.$loading(true)
                const response = await EndPointNet(
                    `membresia?qry=${this.NombreMem || null}&idAgent=${this.idagenteSelect || null}&Colectivo=${this.idcolectividadSelect || null}`,
                    'GET'
                )
                this.$loading(false)
                if (response.status === 200) {
                    this.contratos = [...response.data];  // Copiar los datos para evitar referencias directas

                    this.activas = this.contratos?.filter(item => item.Estatus === 'ACTIVO').length;
                    this.suspendidas = this.contratos?.filter(item => item.Estatus === 'SUSPENDIDO').length;
                    this.totalbajas = this.contratos?.filter(item => item.Estatus === 'BAJA').length;
                }
            } catch (error) {
                this.$loading(false)
                this.$mostrarSwal({
                    title: 'Error',
                    text: 'Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    showCloseButton: true,
                    showConfirmButton: true,
                })
            }
            this.idcolectividad= null
        },
        actualizaNumCitas(membresiaId) {
          EndPointNet(`Membresia/Numerocitas${this.MembresiaSelect.Id}`, "GET")
            .then((response) => {
              if (response.status === 200) {
                //actualiza localmente sin recargar la tabla membresias
                const index = this.contratos.findIndex(item => item.Id ===  membresiaId);
                if (index !== -1) {
                  this.contratos[index].NumCitas = response.data[0].NumCitas;
                }

                this.MembresiaSelect.NumCitas = response.data[0].NumCitas
                //this.MembresiaSelect.Estatus  = response.data[0].Estatus
              }
            })
            .catch(() => {
              this.$loading(false);
            });
      },
    DescargaZip() {
      if(this.idcolectividad){
        this.$loading(true);
        var urltarjet = `${process.env.VUE_APP_FORMATOS}Tarjeta/Colectividad?guid=${this.idcolectividad}`;
        var newTab = window.open(urltarjet);
        if (newTab) {
          newTab.focus();
          this.$loading(false);
        }
      }
    },
    Buscar() {
      this.getMembresias()
    },
    changeSearch() {
      this.idcolectividadSelect = null
      this.idagenteSelect = null
    },
    onActionSelected(action, item) {
      this.MembresiaSelect = {...item }
      switch (action) {
        case 'BENEFICIADOS':
          this.dialogBeneficiados = true
          break;
        case 'PASES MEDICOS':
          this.dialogPases = true
          break;
        case 'RECIBOS':
          this.dialogPases = true
          break;
        case 'TARJETA DIGITAL':
          this.$onDescargaTarjeta(item.Id)
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>