import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);
const opts = {}
export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
    iconfont: 'sm',
    opts,
    theme: {
        themes: {
          light: {
            primary: '#1976D2',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
            azul_med: '#6598C1',
            azulclaro: '#9cbcd7',
            azulfuerte: '#2d567a',
            grisClaro:"#cccccc",
            gris:"#666666",
            verde: '#12a14b',
            rojoalert: '#d07575',
          }
        }
      }
});
